import { globalVariable } from "../../../globalVariable";
import { ListRespone } from "../../../models/commom";
import { axiosClient } from "./axiosClient";
import { InforProduction } from "../../../models/InforProduction";

export const InforProductionApis = {
  GetListInforProductionAdmin: (pageSize: number, pageNumber: number): Promise<ListRespone<InforProduction>> => {
    const url = `/InforProduction/GetListInforProductionAdmin?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetInforProductionByIdCategoriesService: (idCategoriesService: string): Promise<ListRespone<InforProduction>> => {
    const url = `/InforProduction/GetInforProductionByIdCategoriesService?idCategoriesService=${idCategoriesService}`;
    return axiosClient.get(url);
  },
  GetListInforProductionByIdCategoriesService: (
    pageSize: number,
    pageNumber: number,
    idCategoriesService: string
  ): Promise<ListRespone<InforProduction>> => {
    const url = `/InforProduction/GetListInforProductionByIdCategoriesService?idCategoriesService=${idCategoriesService}&pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListInforProductionClient: (pageSize: number, pageNumber: number): Promise<ListRespone<InforProduction>> => {
    const url = `/InforProduction/GetListInforProductionClient?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListInforProductionById: (IdInforProduction: string): Promise<ListRespone<InforProduction>> => {
    const url = `/InforProduction/GetListInforProductionById?IdInforProduction=${IdInforProduction}`;
    return axiosClient.get(url);
  },
  InsertInforProduction: (inforService: Partial<InforProduction>): Promise<ListRespone<InforProduction>> => {
    const url = `/InforProduction/InsertInforProduction`;
    return axiosClient.post(url, inforService);
  },
  UpdateInforProduction: (inforService: Partial<InforProduction>): Promise<ListRespone<InforProduction>> => {
    const url = `/InforProduction/UpdateInforProduction`;
    return axiosClient.put(url, inforService);
  },
  DeleteInforProductionByList: (listId: string[]): Promise<ListRespone<InforProduction>> => {
    const url = `/InforProduction/DeleteInforProductionByList`;
    return axiosClient.delete(url, { data: listId });
  },
  ActiveInforProductionByList: (listId: string[], check: boolean): Promise<ListRespone<InforProduction>> => {
    const url = `/InforProduction/ActiveInforProductionByList?check${check}`;
    return axiosClient.put(url, listId);
  },
  GetFileInforProduction: (IdInforProduction: string) => {
    return `${globalVariable.urlServerApi}/api/InforProduction/GetFileInforProduction?fileNameId=${IdInforProduction}`;
  },
  SaveInforProduction: (file: File) => {
    const url = `/InforProduction/SaveInforProduction`;
    return axiosClient.post(url, file);
  },
  GetListInforProductionByIdParent: (idParent: string): Promise<ListRespone<InforProduction>> => {
    const url = `/InforProduction/GetListInforProductionByIdParent?idParent=${idParent}`;
    return axiosClient.get(url);
  }
} as const;
