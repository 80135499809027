import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Link, useLocation, useParams } from "react-router-dom";
import { GetListCategoryProductClient } from "../../../../../app/CategoryProductsSlice";
import { GetListContactAndIntroductionActived } from "../../../../../app/ContactAndIntroductionSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { GetListInforProductByIdCategoriesProduct } from "../../../../../app/InforProductSlice";
import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { PageTiltle } from "../../../components/pageTille";
import { GetImageInHTMlContent } from "../../homePage";

function _ProductCategory() {
  const { t } = useTranslation(["serviceDetail"]);
  const params = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { loading, apis } = useAppSelector((state) => state.InforProducts);
  const {
    loading: loadingCategoryProduct,
    apis: apisCategoryProduct
  } = useAppSelector((state) => state.CategoryProducts);
  const { loading: loadingContactAndIntroduction, apis: apisContactAndIntroduction } = useAppSelector(
    (state) => state.ContactAndIntroduction
  );
  const titleService = useMemo(() => {
    if (!apisCategoryProduct.listPayload || !params.id) return "";
    return apisCategoryProduct.listPayload?.find((item) => item.id === params.id)?.nameProduct;
  }, [apisCategoryProduct.listPayload, params.id]);

  useEffect(() => {
    document.title = `${titleService || ""} `;
  }, [titleService, t]);
  useEffect(() => {
    if (params.id) {
      dispatch(GetListInforProductByIdCategoriesProduct({
        pageSize: 0,
        pageNumber: 0,
        idCategoriesProduct: params.id as string
      }));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    dispatch(GetListContactAndIntroductionActived({ pageNumber: 0, pageSize: 0, type: 1 }));
    dispatch(GetListCategoryProductClient({ pageNumber: 0, pageSize: 0 }));
  }, [dispatch]);
  return (
    <div className="ProductCategory">
      <PageTiltle title={titleService || ""} subTitle={titleService || ""} breadcrumb={t("serviceDetail:HomeLink")}
                  breadcrumbLink="/" />

      <div className="site-main">
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="row">
                  {loading
                    ? Array(6)
                      .fill(0)
                      .map((item, index) => (
                        <div className="col-md-6 col-lg-6" key={index}>
                          <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                            <div className="featured-thumbnail">
                              <img className="img-fluid" src={"images/services/01.jpg"} alt="image" />
                            </div>
                            <div className="featured-content box-shadow">
                              <div className="featured-title" style={{ height: 62 }}>
                                <h5>
                                  <Link to={`/product-category-${item.id}`}>
                                    <Skeleton count={1} />
                                  </Link>
                                </h5>
                              </div>
                              <div className="featured-desc">
                                <p>
                                  <Skeleton count={5} />
                                </p>
                              </div>
                              <Link
                                className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                                to={`/product-category-${item.id}`}
                              >
                                {t("serviceDetail:ReadMore")} <i className="ti ti-angle-double-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))
                    : apis?.listPayload?.map((item) => (
                      <div className="col-md-6 col-lg-6" key={item.id}>
                        <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                          <div className="featured-thumbnail">
                            <img
                              className="img-fluid"
                              src={GetImageInHTMlContent(item.contentProduct) || "images/logoBigSize.jpg"}
                              style={{ height: 270, width: "100%" }}
                              alt="image"
                            />
                          </div>
                          <div className="featured-content box-shadow">
                            <div
                              className="featured-title"
                              style={{
                                height: 50,
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                marginBottom: 10
                              }}
                              data-tooltip={item.titleProduct}
                            >
                              <h5>
                                <Link
                                  to={item.status === 1 ? `/product-detail-${item.id}` : `/product-child-category-${item.id}`}>{item.titleProduct}</Link>
                              </h5>
                            </div>
                            <div
                              className="featured-desc"
                              style={{
                                height: 75,
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical"
                              }}
                              data-tooltip={item.description}
                            >
                              <p>{item.description}</p>
                            </div>
                            <Link
                              className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                              to={item.status === 1 ? `/product-detail-${item.id}` : `/product-child-category-${item.id}`}
                            >
                              {t("serviceDetail:ReadMore")} <i className="ti ti-angle-double-right"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <aside className="widget widget-nav-menu">
                  <ul className="widget-menu">
                    {loadingCategoryProduct
                      ? Array(6)
                        .fill(0)
                        .map((item, index) => (
                          <li key={index}>
                            <Skeleton count={1} />
                          </li>
                        ))
                      : apisCategoryProduct.listPayload?.map((item, index) => (
                        <li key={item.id} className={location.pathname.includes(item.id) ? "active" : ""}>
                          <Link to={`/product-category-${item.id}`}>{item.nameProduct}</Link>
                        </li>
                      ))}
                  </ul>
                </aside>

                <aside className="widget widget_media_image">
                  <div className="banner-img-box ttm-textcolor-white text-left">
                    <div className="featured-content featured-content-banner">
                      <i className="flaticon flaticon-call"></i>
                      <div className="featured-title ttm-box-title">
                        <h5>{t("serviceDetail:HowCanWeHelp1")}</h5>
                      </div>
                      <div className="featured-desc">
                        <p>{t("serviceDetail:HowCanWeHelp2")}</p>
                      </div>
                      <ul>
                        <li>
                          <i className="fa fa-phone"></i>
                          {loadingContactAndIntroduction ?
                            <Skeleton count={1} /> : apisContactAndIntroduction.listPayload[0]?.col3}
                        </li>
                        <li>
                          <i className="fa fa-envelope-o"></i>
                          <a href={`mailto:${apisContactAndIntroduction.listPayload[0]?.col1}`}>
                            {loadingContactAndIntroduction ?
                              <Skeleton count={1} /> : apisContactAndIntroduction.listPayload[0]?.col1}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const ProductCategory = WithErrorBoundaryCustom(_ProductCategory);
