import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { GetListInforProductionByIdParent } from "../../../../../app/InforProductSlice";
import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { PageTiltle } from "../../../components/pageTille";
import { GetImageInHTMlContent } from "../../homePage";
import { InforProduction } from "../../../../../models/InforProduction";
import { InforProductionApis } from "../../../api/inforProductApis";

function _ProductCategoryChild() {
  const { t } = useTranslation(["serviceDetail"]);
  const params = useParams();
  const dispatch = useAppDispatch();
  const { loading, apis } = useAppSelector((state) => state.InforProducts);
  const [infoProduct, setInfoProduct] = useState<InforProduction>();

  useEffect(() => {
    if (params.id) {
      dispatch(GetListInforProductionByIdParent({
        idParent: params.id as string
      }));
    }
  }, [dispatch, params.id]);
  useEffect(() => {
    (async () => {
      const res = await InforProductionApis.GetListInforProductionById(params.id as string);
      setInfoProduct(res.payload);
      document.title = `${res.payload?.titleProduct || ""} `;
    })();
  }, [params.id]);

  return (
    <div className="ProductCategoryChild">
      <PageTiltle title={infoProduct?.titleProduct || ""} subTitle={infoProduct?.titleProduct || ""}
                  breadcrumb={t("serviceDetail:HomeLink")}
                  breadcrumbLink="/" />
      <div className="site-main">
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 content-area order-lg-2">
                <div className="row">
                  {loading
                    ? Array(6)
                      .fill(0)
                      .map((item, index) => (
                        <div className="col-md-4 col-lg-4" key={index}>
                          <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                            <div className="featured-thumbnail">
                              <img className="img-fluid" src={"images/services/01.jpg"} alt="image" />
                            </div>
                            <div className="featured-content box-shadow">
                              <div className="featured-title" style={{ height: 62 }}>
                                <h5>
                                  <Link to={`/product-category-${item.id}`}>
                                    <Skeleton count={1} />
                                  </Link>
                                </h5>
                              </div>
                              <div className="featured-desc">
                                <p>
                                  <Skeleton count={5} />
                                </p>
                              </div>
                              <Link
                                className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                                to={`/product-category-${item.id}`}
                              >
                                {t("serviceDetail:ReadMore")} <i className="ti ti-angle-double-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))
                    : apis?.listPayload?.map((item) => (
                      <div className="col-md-4 col-lg-4" key={item.id}>
                        <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                          <div className="featured-thumbnail">
                            <img
                              className="img-fluid"
                              src={GetImageInHTMlContent(item.contentProduct) || "images/logoBigSize.jpg"}
                              style={{ height: 270, width: "100%" }}
                              alt="image"
                            />
                          </div>
                          <div className="featured-content box-shadow">
                            <div
                              className="featured-title"
                              style={{
                                height: 50,
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                marginBottom: 10
                              }}
                              data-tooltip={item.titleProduct}
                            >
                              <h5>
                                <Link
                                  to={item?.status === 1 ? `/product-detail-${item.id}` : `/product-child-category-${item.id}`}>{item.titleProduct}</Link>
                              </h5>
                            </div>
                            <div
                              className="featured-desc"
                              style={{
                                height: 75,
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical"
                              }}
                              data-tooltip={item.description}
                            >
                              <p>{item.description}</p>
                            </div>
                            <Link
                              className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                              to={item?.status === 1 ? `/product-detail-${item.id}` : `/product-child-category-${item.id}`}
                            >
                              {t("serviceDetail:ReadMore")} <i className="ti ti-angle-double-right"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const ProductCategoryChild = WithErrorBoundaryCustom(_ProductCategoryChild);
