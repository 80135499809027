const lang = window.localStorage.getItem("language") || "vi";
const listUrl = {
  vi: "http://daiphatdat.com:82",
  en: "http://daiphatdat.com:82/en",
} as const;
// const listUrl = {
//   vi: "https://adminbackend.daiphatdat.com:116",
//   en: "https://adminbackend.daiphatdat.com:116/en"
// } as const;
export const globalVariable = {
  urlServerApi: listUrl[lang as keyof typeof listUrl]
} as const;
