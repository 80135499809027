import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { GetListNewsClient } from "../../../../app/NewsSlice";
import WithErrorBoundaryCustom from "../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { GetImageInHTMlContent } from "../../features/homePage";
import { useTranslation } from "react-i18next";
import blog01 from "./../../asset/images/blog/01.jpg";

function _Footer() {
  const { t } = useTranslation(["header", "footer"]);
  const dispatch = useAppDispatch();
  const { loading, apis } = useAppSelector((state) => state.ContactAndIntroduction);
  const { loading: loadingNews, apis: apisNews } = useAppSelector((state) => state.News);
  const {
    loading: loadingCategoryServices,
    apis: apisCategoryServices,
  } = useAppSelector((state) => state.CategoryServices);

  const [isToTopVisible, setIsToTopVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 100) {
      setIsToTopVisible(true);
    } else {
      setIsToTopVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    dispatch(GetListNewsClient({ pageNumber: 1, pageSize: 3 }));
  }, [dispatch]);

  return (
    <Fragment>
      <div className="Footer">
        <footer className="footer widget-footer clearfix">
          <div className="first-footer ttm-bgcolor-skincolor ttm-bg ttm-bgimage-yes bg-img1">
            <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
            <div className="container">
              <div className="row align-items-md-center">
                <div className="col-lg-8 col-md-4 col-sm-6 order-md-1">
                  <div className="text-left">
                    <div className="featured-icon-box left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-icon_element-color-white ttm-icon_element-size-md">
                          <i className="ti ti-location-pin"></i>
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-desc">
                          <p>{loading ? <Skeleton count={1} /> : apis.listPayload[0]?.col}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 order-md-3">
                  <div className="text-sm-right">
                    <a
                      className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-icon-btn-left ttm-btn-color-white"
                      href={`mailto:${apis.listPayload[0]?.col1}`}
                      title=""
                    >
                      {loading ? <Skeleton count={1} /> : apis.listPayload[0]?.col1} <i
                      className="fa fa-envelope-o"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="second-footer ttm-textcolor-white bg-img2" style={{
            background: "#1e2637",
          }}>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                  <div className="widget widget_text  clearfix">
                    <h3 className="widget-title">{t("footer:NameCompany")}</h3>

                    <div className="quicklink-box" style={{
                      background: "#1e2637",
                    }}>
                      <div className="featured-icon-box left-icon">
                        <div className="featured-content">
                          <div className="featured-desc">
                            <p>{t("footer:WorkingHours")}</p>
                            <p>{t("footer:WorkingDays")}</p>
                          </div>
                          <div className="featured-title">
                            <h5>{loading ? <Skeleton count={1} /> : apis.listPayload[0]?.col2}</h5>
                          </div>
                          {/* <div className="featured-desc">
                            <p>Thứ 7</p>
                          </div>
                          <div className="featured-title">
                            <h5>8:00 sáng - 12:00 chiều</h5>
                          </div> */}

                          <div className="separator">
                            <div className="sep-line mt-25 mb-25"></div>
                          </div>

                          <div className="featured-desc">
                            <p>{t("footer:SupportDepartment")}</p>
                          </div>
                          <div className="featured-title">
                            <h5>{loading ? <Skeleton count={1} /> : apis.listPayload[0]?.col3}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                  <div className="widget link-widget clearfix">
                    <h3 className="widget-title">{t("footer:QuickLinks")}</h3>
                    <ul id="menu-footer-services">
                      {loadingCategoryServices
                        ? Array(5)
                          .fill(0)
                          .map((item, index) => (
                            <li key={index}>
                              <Skeleton count={1} />
                            </li>
                          ))
                        : apisCategoryServices.listPayload.map((item, index) => (
                          <li key={item.id}>
                            <Link to={`/services-category-${item.id}`}>{item.nameService}</Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                  <div className="widget widget_text clearfix">
                    <h3 className="widget-title">{t("footer:News")}</h3>
                    <ul className="widget-post ttm-recent-post-list">
                      {loadingNews
                        ? Array(5)
                          .fill(0)
                          .map((item, index) => (
                            <li key={index}>
                              <Link to={`/blog-detail-${item.id}&null`}>
                                <Skeleton count={1} />
                              </Link>
                              <span className="post-date">
                                  <Skeleton count={1} />
                                </span>
                            </li>
                          ))
                        : apisNews.listPayload.slice(0, 3).map((item, index) => (
                          <li key={item.id}>
                            <Link to={`/blog-detail-${item.id}&null`}>
                              <img
                                src={GetImageInHTMlContent(item.contentNews) || blog01}
                                alt="post-img"
                                style={{
                                  objectFit: "cover",
                                }}
                              />
                            </Link>
                            <Link
                              data-tooltip={item.titleNews}
                              to={`/blog-detail-${item.id}&null`}>{item.titleNews?.slice(0, 65)}{item.titleNews?.length > 65 ? "..." : ""}</Link>
                            <span className="post-date">
                                <i className="fa fa-calendar"></i>
                              {moment(item.createdDate).format("MMM DD, YYYY")}
                              </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                  <div className="widget flicker_widget clearfix">
                    <h3 className="widget-title">{t("footer:Newsletter")}</h3>
                    <div className="textwidget widget-text">
                      {t("footer:Describe")}
                      {/* <form id="subscribe-form" className="newsletter-form" method="post" action="#" data-mailchimp="true">
                        <div className="mailchimp-inputbox clearfix" id="subscribe-content">
                          <i className="fa fa-envelope"></i>
                          <input type="email" name="email" placeholder="Email Address.." />
                          <input type="submit" value="" />
                        </div>
                        <div id="subscribe-msg"></div>
                      </form> */}
                      <h5 className="mb-20">{t("footer:FollowUsAt")}</h5>
                      <div className="social-icons circle social-hover">
                        <ul className="list-inline">
                          <li className="social-facebook">
                            <a
                              className="tooltip-top"
                              href="https://www.facebook.com/profile.php?id=100087794845773"
                              data-tooltip="Facebook"
                              target={"_blank"}
                            >
                              <i className="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                          </li>
                          {/* <li className="social-twitter">
                            <Link className="tooltip-top" to="#" data-tooltip="Twitter">
                              <i className="fa fa-twitter" aria-hidden="true"></i>
                            </Link>
                          </li>
                          <li className="social-linkedin">
                            <Link className=" tooltip-top" to="#" data-tooltip="LinkedIn">
                              <i className="fa fa-linkedin" aria-hidden="true"></i>
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white" style={{
            background: "#1e2637",
          }}>
            <div className="container">
              <div className="row copyright">
                <div className="col-md-6">
                  <div className="">
                    <span>
                      Copyright © 2022&nbsp;<Link to="/">DPD Tech</Link>
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-md-right res-767-mt-10">
                    <div className="d-lg-inline-flex">
                      <ul id="menu-footer-menu" className="footer-nav-menu">
                        <li>
                          <NavLink to="/about-us">{t("header:about")}</NavLink>
                        </li>
                        <li>
                          <NavLink to="/services">{t("header:solution")}</NavLink>
                        </li>
                        <li>
                          <NavLink to="/contact-us">{t("header:contact")}</NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>

      <a
        id="totop"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        className={isToTopVisible ? "top-visible" : ""}
        style={{
          cursor: "pointer",
        }}
      >
        <i className="fa fa-angle-up"></i>
      </a>
    </Fragment>
  );
}

export const Footer = WithErrorBoundaryCustom(_Footer);
